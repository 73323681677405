import { FlexibleTimeSpecificationDefinition } from "./flexible-time-specification-definition";

export class FlexibleDateTime {
  timestamp!: Date;
  flexibleSpecification!: FlexibleTimeSpecificationDefinition;

  public constructor(init?: Partial<FlexibleDateTime>) {
    Object.assign(this, init);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static fromJson(item: any): FlexibleDateTime {
    const to = new FlexibleDateTime({
      timestamp: new Date(item.timestamp),
      flexibleSpecification: item.flexibleSpecification.toString()
    });

    return to;
  }

  public static fromDateFix(item: Date): FlexibleDateTime {
    return new FlexibleDateTime({
      timestamp: new Date(item),
      flexibleSpecification: FlexibleTimeSpecificationDefinition.Fix
    });
  }
}
