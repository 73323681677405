import { Injectable, InjectionToken, inject, signal } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { TranslateService } from "@ngx-translate/core";
import { FlexibleTimeSpecificationDefinition } from "../models/flexible-time-specification-definition";
import { Observable } from 'rxjs';

/** Injection token for the available locales in the application config (all lowercase). */
export const AVAILABLE_LOCALES = new InjectionToken<string[]>('AVAILABLE_LOCALES');

@Injectable({
  providedIn: "root",
})
export class LocalizationService {

  private readonly flexTimePrefix = "frontend.flexible-time-options.";
  private readonly flexTimePostfix = ".label";

  /** The available locales in the application config. */
  private readonly applicationLocales: string[]= inject(AVAILABLE_LOCALES);

  /** The available locales in the application config with normlized names. */
  public readonly availableLocales: string[];

  /** Browser localStorage key where the user selected locale is saved. */
  private readonly languageStorageKey = 'selectedlanguage';

  /** The default application locale that must exists. */
  private readonly defaultLocale = 'de-CH';

  /** The user's browser locales that intersect with the app available localizations. */
  private supportedBrowserLocales : string[];

  /** A previously selected user locale exists or the browser locales were successfully queried. */
  protected hasUserLocale: boolean = false;

  /** The active locale. */
  private currentLocale = signal(this.defaultLocale);

  /** The active locale. */
  public locale = this.currentLocale.asReadonly();

  public constructor(private translateService: TranslateService) {
    // normalize the application locales, so if the user's browser has no info on the selected language,
    // it will be in the correct format/capitalization
    this.availableLocales = this.applicationLocales.map(x => this.normalizeLocaleId(x));

    this.translateService.onLangChange.pipe(takeUntilDestroyed()).subscribe(() => {
      console.debug('New locale selected: ', this.locale());
    });

    // cross reference the user's browser locales with the app config available translations
    this.supportedBrowserLocales = navigator.languages
      .filter(x => this.availableLocales.find(l => l.toLowerCase() === x.toLowerCase()));
  }

  /** Initialize Translation plugin. */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public initialize(): Observable<any>{
    this.translateService.addLangs(this.availableLocales);
    if(null == this.getLocaleFromAvailable(this.defaultLocale)) {
      console.error('Default localization file is missing from available locales!');
    }
    this.translateService.setDefaultLang(this.defaultLocale.toLowerCase());
    this.currentLocale.set(this.defaultLocale);

    // load user selected locale (in the previous session) if it is present in 'availableLocales'
    const userSelectedLocale = this.retrieveUserSelectedLanguage();
    let userLocale = null;
    if(userSelectedLocale){
      userLocale = this.getLocaleFromAvailable(userSelectedLocale);
    }

    // load the user's browser locale if it is present in 'availableLocales'
    const browserLocaleRaw = this.translateService.getBrowserCultureLang();
    let browserLocale = null;
    if(browserLocaleRaw) {
      browserLocale = this.getLocaleFromAvailable(browserLocaleRaw);
    }

    this.hasUserLocale = !!(userLocale ?? browserLocale);

    const newLocale = userLocale ?? browserLocale ?? this.defaultLocale;
    this.currentLocale.set(newLocale);
    return this.translateService.use(newLocale.toLowerCase());
  }

  /** Normalizes locale string. */
  private normalizeLocaleId(locale: string): string;
  private normalizeLocaleId(locale: null): null;
  private normalizeLocaleId(locale: string | null | undefined): string | null;
  private normalizeLocaleId(locale: string | null | undefined): string | null {
    if(locale == null) {
      return null;
    }
    const parts = locale.split('-');
    parts.forEach((value, idx, arr) =>{
      if(idx === 0) {
        // 2 or 3 letter language code
        arr[idx] = value.toLowerCase();
      } else if(value.length === 2) {
        arr[idx] = value.toUpperCase();

        // note: only supports region part, not using the rest (extensions, variants, etc.).
        return;
      }
    });
    return parts.join('-');
  }

  /** Persists the user selected language to browser local storage. */
  private persistUserSelectedLanguage(language: string) {
    localStorage.setItem(this.languageStorageKey, language);
  }

  /** Loads the language from local storage, that the user has previously selected. */
  private retrieveUserSelectedLanguage(): string | null {
    return this.normalizeLocaleId(localStorage.getItem(this.languageStorageKey));
  }

  /**
   * Try to find the locale in the available values.
   * @param locale Locale Id
   * @returns null if not found.
   */
  private getLocaleFromAvailable(locale: string|null|undefined): string|undefined {
    if(locale == null || locale == undefined){
      return undefined;
    }
    const needle = locale.toLowerCase();
    return this.availableLocales.find(l => l.toLowerCase() === needle);
  }

  /**
   * Changes the UI language.
   * Tries to match the selected language to a localization region.
   * @param languageCode The 2 letter language code.
   */
  public changeLanguage(languageCode: string) {
    languageCode = languageCode.toLowerCase();
    let newLocaleId: string|undefined;

    // find matching languages from the user's browser
    if(!newLocaleId) {
      const matching = this.supportedBrowserLocales.filter(x => x.startsWith(languageCode));
      if(matching.length === 1){
        newLocaleId = matching[0];
      } else {
        // prefer -CH region if available
        const chRegion = languageCode + '-' + 'CH';
        newLocaleId = matching.find(x => x === chRegion);
      }
    }

    // find locales from the available locales
    if(!newLocaleId) {
      const matching = this.availableLocales.filter(x => x.startsWith(languageCode));
      if(matching.length === 1){
        newLocaleId = matching[0];
      } else {
        // prefer -CH region if available
        const chRegion = languageCode + '-' + 'CH';
        newLocaleId = matching.find(x => x === chRegion);
      }
    }

    // use default if available.
    // If the Browser locale not available or no combination was valid, try to create a locale from the language.
    // Usually there is a region that matches the language (at least for now, for this App).
    if(!newLocaleId) {
      newLocaleId = this.getLocaleFromAvailable(languageCode + '-' + languageCode.toUpperCase());
    }

    this.changeLocale(newLocaleId ?? this.defaultLocale);
  }

  /**
   * Changes the UI locale.
   * @param localeId The locale Id (the region part must be uppercase. e.g.: de-CH).
   */
  public changeLocale(localeId: string) {
    localeId = this.normalizeLocaleId(localeId);
    let newLocaleId = this.getLocaleFromAvailable(localeId);
    if(newLocaleId){
      this.persistUserSelectedLanguage(newLocaleId);
    }

    newLocaleId = newLocaleId ?? this.defaultLocale
    this.currentLocale.set(newLocaleId);
    this.translateService.use(newLocaleId.toLowerCase());
  }

  /** Retrieve the available language select options present in the application config. */
  public getLanguageSelectOptions() : string[]{
    return this.availableLocales.map(x => x.substring(0, 2));
  }

  /** Retrieve the language code from the currently selected locale. */
  public getLocaleAsTwoLetterCode(): string {
    return this.currentLocale().substring(0,2);
  }

  /** Retrieve the localization for the Flexible time specification enum values. */
  public translateFlexibleTime(flex: FlexibleTimeSpecificationDefinition) {
    return this.translateService.instant(this.flexTimePrefix + flex.toLowerCase() + this.flexTimePostfix);
  }
}
