import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, take } from 'rxjs';
import { AlertModalButtonMode,
  AlertModalResultEnum,
  AlertModalService,
  AlertModalSettings,
  TextAlignment,
} from "@infosystem/baf-angular-web-common";
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: "root",
})
export class NavigationConfirmationRequiredGuard {

  isNavigationConfirmationRequired$ = new BehaviorSubject<boolean>(false);

  constructor(
    private translateService: TranslateService,
    private alertModalService: AlertModalService,
  ){

  }

  /** Updates the state (e.g.: from a component where the form is dirty) */
  public update(newValue: boolean): void {
    this.isNavigationConfirmationRequired$.next(newValue);
  }

  /** Shows a confirmation popup if navigation must be confirmed by the user. */
  public navigateOrConfirm() : Observable<boolean> {
    return new Observable<boolean>((observer) => {

      this.isNavigationConfirmationRequired$.pipe(take(1)).subscribe((mustConfirm: boolean) => {
        if(!mustConfirm) {
            observer.next(true);
            observer.complete();
            return;
          }

          this.alertModalService.open(new AlertModalSettings(
            this.translateService.instant("frontend.resource.actions.confirm-page-leave.text"),
            this.translateService.instant("lib.alert_titel_confirmation"),
            TextAlignment.Left,
            AlertModalButtonMode.OkCancel)).subscribe(result=> {
              observer.next(result.status === AlertModalResultEnum.Ok);
              observer.complete();
            });
      });

    });
  }
}
