import { Injectable } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { TranslateService } from "@ngx-translate/core";
import { MessageService } from "@progress/kendo-angular-l10n";
import { LocalizationService } from './localization.service';
import { CldrIntlService, IntlService } from "@progress/kendo-angular-intl";

@Injectable({ providedIn: "root" })
export class KendoLocalizationService extends MessageService {

  public constructor(
    private translateService: TranslateService,
    private localizationService: LocalizationService,
    private intlService: IntlService,
  ) {
    super();

    this.translateService.onLangChange.pipe(takeUntilDestroyed()).subscribe(() => {
      (<CldrIntlService>this.intlService).localeId = localizationService.locale();

      this.notify();
    });
  }

  /**
   * Returns a localized message for the supplied key.
   *
   * @param _key - The message key. For example, `"kendo.grid.noRecords"`.
   * @return - The localized message for this key or `undefined` if not found.
   */
  public override get(_key: string): string | undefined {
    // Convert camelcase to kebab
    const localizationKey = _key.replace(/([A-Z])/g, '-$1').toLowerCase();

    const message = this.translateService.instant(localizationKey);
    if(message === localizationKey){
      return undefined;
    }
    return message;
  }
}
