
/** Date helper. */
export class DateUtility {

  /** Combines a date and a time. */
  public static create(date: Date, time: Date): Date {
    const newDate = new Date(time.getTime());
    newDate.setFullYear(date.getFullYear());
    newDate.setMonth(date.getMonth());
    newDate.setDate(date.getDate());
    return newDate;
  }

  /** Date.ToString with timezone information. (e.g.: "2024-06-05T18:42:57.0175019+02:00") */
  public static toDateTimeOffsetString(value: Date) : string;
  public static toDateTimeOffsetString(value: null) : null;
  public static toDateTimeOffsetString(value: Date | null) : string | null;
  public static toDateTimeOffsetString(value: Date | null): string | null {
    if(!value){
      return null;
    }

    const tz = value.getTimezoneOffset();
    if(tz === 0) {
      return value.toISOString();
    }

    let str =
      value.getFullYear()+'-'+
      DateUtility.padZero2((value.getMonth() + 1))+'-'+
      DateUtility.padZero2(value.getDate())+'T'+
      DateUtility.padZero2(value.getHours())+':'+
      DateUtility.padZero2(value.getMinutes())+':'+
      DateUtility.padZero2(value.getSeconds());

    const ms = value.getMilliseconds();
    if(ms){
      str + '.' + ms;
    }

    str = str + (tz > 0 ? '-' : '+') +
      DateUtility.padZero2(Math.floor(Math.abs(tz / 60)))+':'+
      DateUtility.padZero2(Math.abs(tz % 60));

    return str;
  }

  /** Pad start with zeroes to the length of 2. */
  private static padZero2(value: number): string {
    return value.toString().padStart(2, '0');
  }
}

