/**
 * Definition of the flexible time specification.
 * This is used to determine how the date is interpreted.
 */
export enum FlexibleTimeSpecificationDefinition {

  /**
   * The time is fixed.
   * The delivery or pick-up must be done at the specified time.
   */
  Fix = 'Fix',

  /**
   * The time is flexible.
   * The delivery or pick-up can be done at any time on the specified date.
   */
  AllDay = 'AllDay',

  /**
   * The time is flexible during the morning.
   * The delivery or pick-up can be done at any time in the morning on the specified date.
   */
  Morning = 'Morning',

  /**
   * The time is flexible during the afternoon.
   * The delivery or pick-up can be done at any time in the afternoon on the specified date.
   */
  Afternoon = 'Afternoon',

  /**
   * The time is flexible after the specified time.
   * The delivery or pick-up can be done at any time after the specified time on the specified date.
   */
  From = 'From' ,

  /**
   * The time is flexible before the specified time.
   * The delivery or pick-up can be done at any time before the specified time on the specified date.
   */
  Until = 'Until',

  /**
   * The previously agreed time will be used.
   */
  AsAgreed = 'AsAgreed',
}
