import { Supplier } from "./supplier";
import { Order } from "./order";

export class GetOrderResponse {
  supplier!: Supplier;
  order!: Order;

  public constructor(init?: Partial<GetOrderResponse>) {
    Object.assign(this, init);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static fromJson(item: any): GetOrderResponse {
    const response = new GetOrderResponse({
      supplier: Supplier.fromJson(item.supplier),
      order: Order.fromJson(item.order)});

    return response;
  }
}
